import { settingsMessages } from 'messages/messages.settings';

import { Button } from 'components/button/Button';
import { H1 } from 'components/heading/Heading';
import { RichTextStatic } from 'components/rich-text/RichTextStatic';

import { useMessages } from 'hooks/useMessages';

import s from './Error404.module.scss';

export const Error404 = () => {
  const { formatMessage } = useMessages();
  return (
    <div className={s.error404}>
      <div>
        <H1>{formatMessage(settingsMessages.errorSiteTitle)}</H1>
        <RichTextStatic>
          <p>{formatMessage(settingsMessages.errorSiteDescription)}</p>

          <ul>
            <li>{formatMessage(settingsMessages.errorSitePageNotExist)}</li>
            <li>{formatMessage(settingsMessages.errorSitePageRemoved)}</li>
            <li>{formatMessage(settingsMessages.errorSitePageNotAvailable)}</li>
          </ul>
        </RichTextStatic>

        <Button to="/" className={s.error404__button}>
          {formatMessage(settingsMessages.errorSiteButtonText)}
        </Button>
      </div>
    </div>
  );
};
