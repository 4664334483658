import { Error404 } from 'components/error/Error404';
import { Section } from 'components/section/Section';

export default function Custom404() {
  return (
    <Section container>
      <Error404 />
    </Section>
  );
}
